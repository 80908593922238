import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Drill Sergeant" />
    <h1>DRILL SERGEANT</h1>
    <p>Infos about Mr. Drill Sergeant himself are coming soon ... </p>
    <p>Phrases to get you started:</p>
    <ul>
      <li>"Alexa, tell Drill Sergeant that I did 50 push-ups"</li>
      <li>"Alexa, open Drill Sergeant"</li>
      <li>... "Start Yoga session"</li>
      <li>... "End Yoga session"</li>
      <li>... "I drank 1 glas of water"</li>
      <li>... "Stretching for 20 minutes"</li>

    </ul>
    <Link to="/DrillSergeant/terms">Terms</Link> <br/>
    <Link to="/DrillSergeant/privacy">Privacy</Link> 
  </Layout>
)

export default IndexPage